.searchHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 10px;
}

.searchHeader h1{
    color: #877AFF;
}