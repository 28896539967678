.App {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}

.sidePanel{
  margin: 0;
  padding-top: 20px;
  padding-left: 40px;
  color: white;
  margin-right: 80px;
}

.dokitaCanvas{
  margin-top: 60px;
  margin-right: 20px;
}

.AboutButton{
  display: flex;
  background-color: #130E43;
  padding: 5px 0px 5px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px; 
  margin-top: 40px;
  text-align: center;
}

.AboutTitle{
  font-size: 18px;
  color: #8986A1;
  width: 50px;
}

.axamatticBadge{
  position: absolute;
  width: 100px;
  bottom: 0;
  margin-bottom: 40px;
}



@media screen and (max-width: 1100px) {

  .sidePanel{
    margin-right: 0;
  }

  .DokitaTitle{
    font-size: 18px;
  }

  .AboutButton{
    display: flex;
    background-color: #130E43;
    padding: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 10px; 
    margin-top: 20px;
    max-width: 80px;
  }
  
  .AboutTitle{
    font-size: 12px;
    color: #8986A1;
    width: 50px;
  }

  .axamatticBadge{
    position: absolute;
    width: 80px;
    bottom: 0;
    margin-bottom: 20px;
  }
  

}