.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 40px;
}

.aboutTitle{
    color: #877AFF;
}

.bodyCopy{
    color: #fff;
    margin-top: 20px;
}

.axaAboutBadge{
    color: #877AFF;
    font-weight: 400;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 10px;
}

.axaAboutLink{
    text-decoration: none;
    color: #877AFF;
}