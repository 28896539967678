.filterContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dotCanvas{
    display: flex;
    justify-content: end;
    padding-right: 150px;
    padding-top: 70px;
    padding-bottom: 0px;
}

.pointsShowingContainer{
    position: absolute;
    display: flex;
    flex-direction: row;
    color: white;
    background-color: #1A154D;
    border-radius: 10px;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 250px;
    margin-bottom: -60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.pointsShowingBox{
    width: 20px;
    height: 20px;
    background-color: #FF12CB;
    border-radius: 4px;
    margin: 0px;
    padding: 0px;
    margin-right: 10px;
}

.pointsShowingTitle{
    margin: 0px;
    padding: 0px;
}


.main {
    position: relative;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.circle {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.circle:nth-child(1) {
    transform: rotate(-40deg) translateX(350px);
}

.circle:nth-child(2) {
    transform: rotate(10deg) translateX(350px);
}

.circle:nth-child(3) {
    transform: rotate(140deg) translateX(350px);
}

.circle:nth-child(4) {
    transform: rotate(180deg) translateX(350px);
}

.circle:nth-child(5) {
    transform: rotate(220deg) translateX(350px);
}


.circle:nth-child(1) .Filter {
    transform: rotate(40deg);
}

.circle:nth-child(2) .Filter {
    transform: rotate(-10deg);
}

.circle:nth-child(3) .Filter {
    transform: rotate(-140deg);
}

.circle:nth-child(4) .Filter {
    transform: rotate(-180deg);
}

.circle:nth-child(5) .Filter {
    transform: rotate(-220deg);
}


@media screen and (max-width: 1250px) {


    .circle:nth-child(1) {
        transform: rotate(-40deg) translateX(300px);
    }

    .circle:nth-child(2) {
        transform: rotate(10deg) translateX(300px);
    }

    .circle:nth-child(3) {
        transform: rotate(140deg) translateX(300px);
    }

    .circle:nth-child(4) {
        transform: rotate(180deg) translateX(300px);
    }

    .circle:nth-child(5) {
        transform: rotate(220deg) translateX(300px);
    }

}


@media screen and (max-width: 850px) {


    .circle:nth-child(1) {
        transform: rotate(-40deg) translateX(250px);
    }
    
    .circle:nth-child(2) {
        transform: rotate(10deg) translateX(250px);
    }
    
    .circle:nth-child(3) {
        transform: rotate(140deg) translateX(250px);
    }
    
    .circle:nth-child(4) {
        transform: rotate(180deg) translateX(250px);
    }
    
    .circle:nth-child(5) {
        transform: rotate(220deg) translateX(250px);
    }
    
    }