.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

a{
    color: #fff;
}

.title{
    color: #877AFF;
    margin: 0;
}

.cross{
    width: 20px;
    height: 20px;
}

.plotContainer{
    color: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
}

.read-more-button{
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    color: #8C8A9B;
}

.infoBox{
    background-color: #100D26;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 5px 20px 5px 20px;
    text-wrap: wrap;
    word-wrap: break-word;
    overflow: hidden;
}
