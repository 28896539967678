.Filter{
    position: absolute;
    display: inline-block;
    justify-content: start;
    align-items: start;
    min-width: 150px;
    min-height: 165px;
}

.Title{
    color: #fff;
    margin: 0px;
    font-size: 16px;
}

ul li .Option{
    color: #878594;
    text-decoration: none;
    list-style-type: none;
    text-align: left;
    margin: 0px;
    margin-bottom: 10px;
    text-transform: lowercase;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
}

ul li .Option:hover{
    color: #FF12CB;
}

ul li .Active{
    color: #fff;
    text-decoration: none;
    list-style-type: none;
    text-align: left;
    margin: 0px;
    margin-bottom: 10px;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

ul li .Active::first-letter{
    text-transform: uppercase;
}

ul li .Option::first-letter{
    text-transform: uppercase;
}

ul, ul li {
    display: inline;
    text-decoration: none;
    list-style-type: none;
    margin: 0px;
    justify-content: left;
}

.OptionItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.fadeOutOptionItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0;
    transition: all 250ms linear 2s
}

.searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: #fff;
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
}

.searchIcon{
    position: relative;
    width: 20px;
    height: 20px;
    top: 3px;
}

.searchTitle{
    color: #fff;
    font-size: 14px;
    margin-left: 2px;
    font-weight: 300;
}

.CrossIcon{
    position: relative;
    width: 12px;
    height: 12px;
    bottom: 3px;
    margin-left: 5px;
}